import React, { Suspense, lazy, useEffect, useState } from "react";
import { Container } from "reactstrap";
import { useNavigate } from "react-router";
import { Auth } from "aws-amplify";

const Demo = lazy(() => import("../Demo"));
const FeatureList = lazy(() => import("./FeatureList"));

const Landing = () => {

    let navigate = useNavigate();

    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

    useEffect(() => {
        async function checkUserAuthentication(): Promise<boolean> {
            try {
                await Auth.currentAuthenticatedUser();
                return true;
            } catch (error) {
                return false;
            }
        }
        checkUserAuthentication().then((isAuthentcated) => {
            setIsAuthenticated(isAuthentcated);
        });
    }, [])

    useEffect(() => {
        if (isAuthenticated) {
            navigate("/Dashboard");
        }
    }, [isAuthenticated])


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <h1 className="d-flex justify-content-center">The world's most trusted and advanced bank statement converter</h1>
                    <h2 className="lead mb-5 d-flex justify-content-center">Effortlessly transform PDF bank statements from hundreds of global banks into a streamlined Excel (XLS or CSV) format.</h2>
                    <div className="alert alert-info alert-dismissible fade show" role="alert">
                        👋 Hello BankStatementConvertAI users! We just released a new app to create short videos about any topic (finances, dieting, nutrition, exercising, and more).
                        To try it out, visit <a target="_blank" href="https://shortvideocreator.com">ShortVideoCreator.com</a>
                    </div>
                    <Suspense fallback={<div>Loading...</div>}>
                        <FeatureList />
                    </Suspense>
                    <Suspense fallback={<div>Loading...</div>}>
                        <Demo embed={true} />
                    </Suspense>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default Landing;
